
import React, { useState, useEffect, useContext  } from "react";
import axios, { all } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../components/Header'
import HeaderImage from '../../images/contact.jpg'
import {MdEmail} from 'react-icons/md'
import {BsMessenger } from 'react-icons/bs'
import {IoLogoWhatsapp} from 'react-icons/io'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import Footer from '../../components/Footer';
import './contact.css'


const Contact = () => {
  const [contact, setContact] = useState({});
  const [page, setPage] = useState({});
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState('');

  useEffect(() => {
    fetchPage(); 
  }, []);

  async function fetchPage() {
    const config = {
      params: { name:'contact'}, // query parameters 
    };
    try {
        const response = await axios.get('https://www.eldsmw.org:3000/api/pages', config);
        const data = response.data;
        // console.table('page API Response sir :', data);
        const page= data[0];
        const pageArticles = data[0].pageArticles; // Assuming there's only one object in the array
        // Set the extracted pageArticles to the projects state
        //setProjects(pageArticles);
        setPage(page)
    } catch (error) {
        console.error('Error fetching data:', error);
        return { count: 0, result: null, error: 'Failed to fetch data. Please try again later.', };
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name,value)
    setContact(prevContact => {const updatedContact = { ...prevContact };
      updatedContact[name] = value;
      return updatedContact;
    });
  };

  
  
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    if (!contact.name) {toast.error('Please enter full name ');return;  }
    if (!contact.email) {toast.error('Please enter email address ');return;  }
    if (!contact.subject) {toast.error('Please enter subject ');return;  }
    if (!value) {toast.error('Please enter message  ');return;  }

    setIsLoading(true);
    
    try {
      const response = await fetch('https://www.eldsmw.org:3000/api/enquiries', {
        method: 'POST',
        headers: {'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${userData.token}`
        },
        body: JSON.stringify({
          surname: contact.name,
          email: contact.email,
          subject: contact.subject,
          content: value ,
        })
      });
  
      if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
  
      const data = await response.json();
      toast.success('Article submitted successfully!');
      //fetchArticles();
   
      // Return data if needed
      return {
        data
      };
    } catch (err) {
      console.error('Error adding article:', err);
      toast.error('Failed to add article. Please try again later.');
      // Handle the error gracefully without breaking the code execution
     // throw new Error('Failed to add article');
    } finally {setIsLoading(false);}
  };
  




  
  
  
  
  
  
  
  const photoUrl = page.pagePhotos?.[0]?.photoUrl;
  return (
    <>
     <div className="flex flex-col items-center min-h-screen">
     <Header title={page.title} description={page.description} imgUrl={photoUrl} />
    <section className="contact">
      <div className="container contact__container">
        <div className="contact__wrapper">
          
          <a href="mailto:support@elds.org" target='_blank' rel='noreferrer noopener'><MdEmail/>
          <span className="text-xs text-center text-blue-300 ">info@eldsmw.org</span>
          </a>
          <a href="http://m.me/mathews.phiri.9843" target='_blank' rel='noreferrer noopener'><BsMessenger/><span className="text-xs text-blue-300">facebook.com/eldsmw</span></a>
          <a href="https://wa.me/+265884543607" target='_blank' rel='noreferrer noopener'><IoLogoWhatsapp/><span className="text-xs text-blue-300">+265 884 54 36 07</span></a>
        </div>



        
      </div>
      <div className=" contact-wrap">
          <h1 className="my-4 text-center text-lg">Write a message </h1>
        </div>
        <div className=" border border-1 justify-center mb-3">
        <form className={` shadow-md rounded px-8 pt-4 mb-2`}>
          
          <div className="relative z-0 mb-3 w-full group">
            <input type="text" className="block py-2.5 px-0 w-full text-sm
            text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
              dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
              focus:ring-0 focus:border-blue-600 peer" 
              name="name"
              onChange={(event) => handleChange(event)}
              value={contact.name}
              />
            <label htmlFor="name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
            transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
            peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Your name</label>
          </div>

          <div className="relative z-0 mb-1 w-full group">
              <input
                type="email"
                name="email"
                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(event) => handleChange(event)}
                value={contact.email}
              />
              
              <label
                htmlFor="email"
                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Your email address
              </label>
            </div>

            <div className="relative z-0 mb-1 w-full group">
              <input
                type="text"
                name="subject"
                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(event) => handleChange(event)}
                value={contact.subject}
              />
              
              <label
                htmlFor="subject"
                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                subject
              </label>
            </div>


          <div className="relative z-0 mb-8 w-full group">
            <label className=" text-gray-500  "
             > </label>
     
     <ReactQuill 
     className="block pb-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
      
     theme="snow" 
     style={{ height: "250px" }} 
        value={value} 
        onChange={setValue} 
     />
    </div>
    
        <div className="my-8">
          <button 
            className="w-full flex items-center justify-center px-5 mb-3 py-3 text-base leading-6 font-medium rounded-md text-white bg-indigo-500 focus:ring hover:bg-indigo-400 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out" type="submit"
            onClick={handleSubmit}
          >
            Send 
          </button>
        </div>


         

        
      </form>


        </div>
    </section>

    </div>





    <Footer/> 
    
    </>
  )
}

export default Contact
