import React from 'react'
import Faq from '../../components/Faq'
import './faqs.css'

const Faqs = () => {
  return (
    <div><Faq/></div>
  )
}

export default Faqs