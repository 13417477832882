import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiSolidFilePdf } from "react-icons/bi";
import { Button } from './Button';
import axios from 'axios';
const Newsletter = ({ imgUrl, title, description, onClosePhotoGallery }) => {
  const [email, setEmail] = useState('');
  const { userData } = useContext(AppContext);
  const [newsletters, setNewsletters] = useState([]);
  const [downloadSuccess ,setDownloadSuccess] = useState(false);


  useEffect(() => {
    // setIsLoading(true)
    fetchNewsLetters();
    // setIsLoading(false)
  }, []);

  const fetchNewsLetters = async () => {
    const config = { headers: { Authorization: `Bearer ${userData.token}` } };
    try {
      const response = await axios.get('https://www.eldsmw.org:3000/api/newsletters', config);
      const data = response.data;
      console.log(data)
      setNewsletters(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleSubscribe = () => {
    console.log('here sir in handle subscribe');
  };

  const handleDownload = async () => {
    setDownloadSuccess(false); // Reset download success flag

    if (newsletters.length > 0) {
      const firstNewsletter = newsletters[0];
      const downloadUrl = firstNewsletter.fileUrl; // Assuming 'fileUrl' is the key containing the download URL

      if (downloadUrl) {
        try {
          const response = await axios.get(downloadUrl, { responseType: 'blob' }); // Download as blob for better compatibility
          const blob = await response.data;
          const url = window.URL.createObjectURL(blob); // Create temporary URL for download
          const fileName = `Newsletter_${firstNewsletter.title}.pdf`; // Set filename

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          link.click();

          // Cleanup: Remove temporary URL after download
          window.URL.revokeObjectURL(url); 

          // Show success message after download completes
          setDownloadSuccess(true);
          toast.success('Download successful !. Check file in your downloads folder');
        } catch (error) {
          toast.error('Error downloading file:', error);
          // Show error message to the user
        }
      } else {
      toast.error("File URL not found.");
      }
    } else {
      toast.error("No newsletters available to download.");
    }
  };

 
  return (
    <div className="mx-4 rounded-lg">
      <ToastContainer position="top-center" autoClose={3000} />
      <div className="rounded-lg relative z-10 flex flex-col bg-gray-700 p-8">
        {/* Check if processedTitle exists before using it */}
        <h1 className="font-bold text-white text-1xl md:text-2xl lg:text-3xl">
          Get the latest updates from ELDS!
        </h1>

        {/* Hero buttons */}
        <div className="flex flex-col md:flex-row gap-2">
          <p id="subscribe" className="text-white text-lg mt-4 ">
            Subscribe to our newsletter and stay updated on the latest developments.
          </p>
          <div className="flex items-center gap-2 justify-between">
            <input
              type="email"
              name="email"
              className="px-2 h-10 w-full rounded-lg"
              placeholder="Enter email address "
              onChange={handleEmailChange}
            />
        
            <Button className=" h-10 rounded-lg bg-blue-500" onClick={handleSubscribe}
             
          
            >
              <span>
                Subscribe 
              </span>
            </Button>
          </div>
          <Button className="btns h-10 rounded-lg" onClick={handleDownload}>
            <span style={{ display: 'flex', alignItems: 'center' }}> {/* Added styles for inline icon */}
              Download
              <BiSolidFilePdf size={30} style={{ color: 'red' }} />  {/* Set icon color to red */}
            </span>
          </Button>      </div>
      </div>
    </div>
  );
};

export default Newsletter;
