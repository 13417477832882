import { Link } from "react-router-dom"
import Logo from '../images/logo.png'
import {FaLinkedin }  from 'react-icons/fa'
import {FaFacebook }  from 'react-icons/fa'
import {AiOutlineTwitter }  from 'react-icons/ai'
import {AiFillInstagram }  from 'react-icons/ai'
import {BsTelephone }  from 'react-icons/bs'

import './footer.css'

const Footer = () => {
  return (
    <footer className="mt-8 ">
        <div className="container footer__container">
        <article className="flex items-center">
          <div className="footer__link">
            <Link to="/" className="">
            <img src={Logo} className="logo" alt="Footer Logo" />
          </Link>
          </div>
          
          <div className="footer__address"> {/* Add margin-left to create space between logo and text */}
            <p>
              Evangelical Lutheran Development Service,
              Off Chidzanja Road, Chipasula turn-off, Lilongwe, Malawi,
            </p>
            <div className="footer__telephone " >
              <p className="flex content-center"> {/* Use flex to align items horizontally */}
                <BsTelephone className="mr-2" /> {/* Add margin-right to create space between icon and text */}
                +265 88 454 36 07
              </p>
            </div>
          </div>
          {/* <div className="footer__socials">
            Your social media icons 
          </div>  */}
        </article>
        <article>
          <h4>Permalinks</h4>
          <Link to="/about">About US</Link>
          <Link to="/projects" >Projects</Link>
        </article>
        <article>
          <h4>Insights</h4>
          <Link to="/gallery" >Gallery</Link>
          <Link to="/faqs" >FAQ</Link>
        </article>
        <article>
          <h4>Get in touch</h4>
          <Link to="/partners" >Get involved</Link>
          <Link to="/contact" >Contact Us</Link>
          {/* <Link to="/faqs" >The Church</Link> */}
        </article>
      </div>
      <div className="footer__copyright">
          <small>&copy; 2023 ELDS. All rights Reserved</small>
      </div>
    </footer>
  )
}

export default Footer