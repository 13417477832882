import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Audio } from 'react-loader-spinner';
import axios, { all } from 'axios';


const AddImage = ({ pages,articles}) => {
const [image, setImage] = useState({ caption: '',pageId: '',articleId: '',tags:'',img: '',imgType: '',displayImg:'',imgUrl:'',});
const [value, setValue] = useState('');
const [isLoading,setIsLoading]=useState(false);
const { userData } = useContext(AppContext);


useEffect(() => {

},[]);


  const Clearimage = () => {// clear form field 
    setImage({caption: '',pageId: '',articleId: '',tags:'',img: '',imgType: '',displayImg:'',imgUrl:'',});
  };

  
  
  const convertFile = (files) => {
    if (files && files.length > 0 && files[0] instanceof File) { // Check if files is a valid file object
      const fileRef = files[0];
      const fileName = fileRef.name; // Get the file name
      const fileType = fileRef.type;
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev) => {
        const base64Data = `data:${fileType};base64,${btoa(ev.target.result)}`;
        const displayImg = `data:${fileType};base64,${btoa(ev.target.result)}`;
        setImage(previmage => ({
          ...previmage,
          img: base64Data,
          displayImg: btoa(ev.target.result),
          imgType: fileType,
          imgUrl: fileName, // Add the fileName to the image state
        }));
      };
    }
  };
  
  const handleImgChange = (event) => {
    const { name, value, files } = event.target;
    console.log('event target name:', event.target.name);
    console.log('event target files:', event.target.files);
    if (name === "img") {convertFile(files);
    } else {setImage(previmage => ({...previmage,[name]: value,})); }
  };

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setImage(prevImg => {
      const updatedImg = { ...prevImg };
      if (name === "caption") {
        updatedImg.caption= value;  
      }else if (name === "tags") {
          updatedImg.tags= value;  
      } else if (name === "page") {
        const selectedPage = pages.find((page) => page.name === value);// Find the selected image and update imageId if found
        if (selectedPage) {updatedImg.pageId = selectedPage.id;
        } else { updatedImg.pageId = 0; }    // If no selected image is found, reset imageId
      } else if (name === "article") {
        const selectedArticle = articles.find((article) => article.title === value);// Find the selected image and update imageId if found
        if (selectedArticle) {updatedImg.articleId = selectedArticle.id;
        } else { updatedImg.articleId = 0; }    // If no selected image is found, reset imageId
      }
      return updatedImg;
    });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
   // Required field checks
    if (!image.caption || !image.tags|| !image.img) {
      toast.error('Please fill in required fields: caption, tags, image');
      return; // Exit function if required fields are missing
    }
    setIsLoading(true);
    const imgObj = { // Construct imagePhotos array
      //file_name: image.imgUrl?.trim() !== '' ? image.imgUrl : "",
      data: image.displayImg, contentType: image.imgType
      // Add any other necessary properties here
    };
    const tags = image.tags.split(",").map((tag) => tag.trim());
    try {
      const response = await fetch(`https://www.eldsmw.org:3000/api/images`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.token}`
        },
        body: JSON.stringify({
          caption: image.caption,
          tags:tags,
          page_id:image.pageId,
          article_id:image.articleId,
          file_name:image.imgUrl,
          photo:imgObj
          
        })
      });


      
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      toast.success('image submitted successfully!');
      //fetchImages();
   
      // Return data if needed
      return {
        data
      };
    } catch (err) {
      console.error('Error adding image:', err);
      toast.success('image updated  successfully!');
     // fetchImages();
      //throw new Error('Failed to add image');
    } finally {
      setIsLoading(false);
    } 
  
  };
 
  
   const photoUrl = image && image.displayImg? `data:${image.imgType};base64,${image.displayImg}` || image.imgUrl
  : "";


  return (
    <div className="">
      <div className="flex">
      <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New Image</h2>
    </div>
  
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="w-full max-w-full" style={{ width: "500px" }}>
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <Audio type="Bars" color="#FFF" height={50} width={50} />
          </div>
        )}    
       
        {/* <form className={`bg-${bgColor} shadow-md rounded px-8 pt-6 mb-4`}> */}
        <form className={` shadow-md rounded px-8 pt-4 mb-2`}>


        <div className="relative z-0 mb-3 w-full group flex items-center">
                <input
                className="block py-2.5 px-0 w-full text-gray-900 bg-transparent bgray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                type="file"
                accept="image/*"
                name='img'
                onChange={(event) => handleImgChange(event)}
                />

                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Upload picture</label>
                <div>
                  {photoUrl && ( // Use a single conditional check for efficiency
                    <img
                      className="rounded-full h-24 w-24"
                      src={photoUrl}
                      alt="image image"
                    />
                  )}
                </div>
            </div>


            <div className="relative z-0 mb-4 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="caption"
                    onChange={(event) => handleTextChange(event)}
                    value={image ? image.caption : ''}
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Caption
                </label>
            </div>

            <div className="relative z-0 mb-4 w-full group">
                {/* <label htmlFor="tagsInput" className="block mb-2">Enter Tags (separated by commas):</label> */}
                <input
                    type="text"
                    id="tags"
                    name="tags"
                    className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer"
                    value={image.tags}
                    onChange={(event) => handleTextChange(event)}
                    placeholder="e.g., tag1, tag2, tag3"
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Enter Tags (separated by commas):
                </label>
            </div>


            <div className="relative z-0 mb-4 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="page"
                    list="pagesList" // Link the input to the datalist
                    onChange={(event) => handleTextChange(event)}
                    value={image ? image.page : ''}
                   
                />
                {/* Use the datalist element for autocomplete */}
                <datalist id="pagesList">
                  {pages.map((page) => (
                    <option value={page.name} />
                  ))}
                </datalist>
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Target Page (if any)
                </label>
            </div>
            
            <div className="relative z-0 mb-4 w-full group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="article"
                    list="articlesList" // Link the input to the datalist
                    onChange={(event) => handleTextChange(event)}
                    value={image ? image.article: ''}
                />
                {/* Use the datalist element for autocomplete */}
                <datalist id="articlesList">
                  {articles.map((article) => (
                    <option value={article.title} />
                  ))}
                </datalist>
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Target Article (if any)
                </label>
            </div>

            
            
        
            

        <a
          className="mt-3 inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          href="#"
        >   
        </a>
      </form>
      <div className="mb-4 ">
<button
  className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
  onClick={handleSubmit}
  
>
  Save
</button>         
        </div>
    </div>
    </div>
  
  )
}

export default AddImage
