import React from 'react';
import { useLocation } from 'react-router-dom';
import News  from '../../components/News';
// import Image from '../../images/foodsecurity.jpg'
const FoodnNutrition = () => {
    const location = useLocation();
    const { title, info,story,icon } = location.state || {};
  
    return (
        <>
        <div className="foodnnutrition__container">
            <div className="foodnnutrition__wrapper">
                <News title={title} image={icon} story={story}/>
            </div>
        </div>
        </>
  )
}

export default FoodnNutrition
