import React, { createContext, useContext, useEffect, useState } from 'react';
import AppContext from './AppContext';
import axios from 'axios';



const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#03C9D7');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [newsletters, setNewsletters] = useState([]);
  const [enquiries, setEnquiries] = useState([]);
  const [articles, setArticles] = useState([]);
  const [pages, setPages] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [users, setUsers] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);
  const [userData, setUserData] = useState([{ id:'',username: '',  role: '', token:'',session:0  ,img:'', email:'',isLoggedIn:false}]);
 
  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
  };

  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

  useEffect(() => {
    fetchNewsletters();
    fetchEnquiries();
    fetchArticles();
    fetchPages();
    fetchTestimonials();
    fetchUsers();
   
  }, [updateCount]);

 



  const fetchNewsletters = async () => {
    const config = { headers: { Authorization: `Bearer ${userData.token}` } };
    try {
      const response = await axios.get('https://www.eldsmw.org:3000/api/newsletters', config);
      const data = response.data;
      setNewsletters(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchEnquiries = async () => {
    //const config = { headers: { Authorization: `Bearer ${userData.token}` } };
    try {
      const response = await axios.get('https://www.eldsmw.org:3000/api/enquiries');
      const data = response.data;
      console.log('enquires in context',enquiries)
      setEnquiries(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchArticles = async () => {
    try {
      const response = await axios.get('https://www.eldsmw.org:3000/api/articles');
      const data = response.data;
      setArticles(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } 
  };

  const fetchPages = async () => {
    try {
      const response = await axios.get('https://www.eldsmw.org:3000/api/pages');
      const data = response.data;
      console.log('pages in context',data)
      setPages(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get('https://www.eldsmw.org:3000/api/testimonials');
      const data = response.data;
      setTestimonials(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
 

  const fetchUserss= async () => {
    try {
      const response = await axios.get(`https://www.eldsmw.org:3000/api/users`, {
        headers: { Authorization: `Bearer ${userData.token}` }, // Add authorization header
      });
      const data = response.data;
      console.log('user in context',data)
      setUsers(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchUsers = async () => {
    console.log('userData.token',userData.token)
    const config = { headers: { Authorization: `Bearer ${userData.token}` } };
    try {
      const response = await axios.get('https://www.eldsmw.org:3000/api/users', config);
      const data = response.data;
      console.log('data',data)
      setUsers(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  
  return (
    <AppContext.Provider 
        value={{
         userData,setUserData,users, setUsers,newsletters,enquiries,articles,pages,testimonials,updateCount,setUpdateCount,
         themeSettings, setThemeSettings,currentColor, 
          setCurrentColor,currentMode,setCurrentMode,activeMenu, setActiveMenu , 
          isClicked, setIsClicked, handleClick, screenSize, setScreenSize,
          initialState,setMode,setColor,
        }}>
        {children}
   </AppContext.Provider>
  );
};

export default ContextProvider;
