import React from 'react'
import Story from '../../components/Story'

const Ourstory = () => {
  return (
    <>
     <Story/>
    </>
   
  )
}

export default Ourstory