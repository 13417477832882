 import './header.css'
const Header = ({title, imgUrl, description}) => {
  console.log('image sir in heder ',imgUrl)
  const parser = new DOMParser();
 
  const doc = description?.trim() ? parser.parseFromString(description, 'text/html') : null;


  const tagStyleMap = {
    b: { fontWeight: 'bold' },
    i: { fontStyle: 'italic' }, // Add more mappings as needed
    u: { textDecoration: 'underline' },
  };

  function processElement(element) {
    if (element.nodeType === Node.ELEMENT_NODE) {
      const tagName = element.tagName.toLowerCase(); // Handle case-insensitive matching
      const style = tagStyleMap[tagName]; // Get styles for the tag

      if (style) {
        element.tagName = 'span'; // Replace with span
        Object.assign(element.style, style); // Apply styles
      }

      // Recursively process child elements
      Array.from(element.childNodes).forEach(processElement);
    }
  }
 let processedDescription=null;
  if (doc && doc.body) {processElement(doc.body);processedDescription = doc?.body.innerHTML;} 
  
 console.log('imgae in hesder',imgUrl)
  

  return (
      <header className="header">
        <div className="header__container">
          <div className="header__container-bg">
          {imgUrl && (<img src={imgUrl} alt="header background" />
)}
          </div>
         <div className="header__content">
         {title && (<h2>{title}</h2>)}
         
 
          {processedDescription && (<p dangerouslySetInnerHTML={{ __html: processedDescription }} />)}
         </div>
           
        </div>
      </header>
    )
  }
  
  export default Header