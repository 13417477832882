import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Audio } from 'react-loader-spinner';
import axios, { all } from 'axios';
import { CiEdit } from "react-icons/ci";
import { MdOutlineCancel } from 'react-icons/md';
import { FcCancel } from "react-icons/fc";
import { BsFillSendFill } from "react-icons/bs";
import { FaUserLarge } from "react-icons/fa6";
import { handleApi } from '../api/api';
const EditUser = ({ rowData, onClose }) => {

const [user, setUser] = useState({});
const [originalUser, setOriginalUser] = useState({});

const [loginId, setLoginId] = useState('');
const [fullName, setFullName] = useState('');
const [email, setEmail] = useState('');
const [title, setTitle] = useState('');
const [roleId, setRoleId] = useState('');
const [imgType, setImgType] = useState('');
const [displayImg, setDisplayImg] = useState('');
const [roles, setRoles] = useState([]);
const [isLoading,setIsLoading]=useState(false);
const [isEditable, setIsEditable] = useState(false); // Add state to manage edit mode
  

const { userData, setUserData,updateCount, setUpdateCount } = useContext(AppContext);

useEffect(() => {
    console.log('row data',rowData)
    setUser(rowData)
 
  fetchRoles();

}, [rowData]);

useEffect(() => {
    setIsLoading(true);
    setUser(rowData);
    setOriginalUser(rowData);
    setFullName(rowData.first_name);
    setEmail(rowData.username);
    setTitle(rowData.title);
    setRoleId(rowData?.user_roles?.name);
    setIsLoading(false); 
}, [rowData]);

  

const resetForm = () => {
  const updatedUser = { 
      first_name: '',
      last_name: '',
      title: '',
      username:'',
      role_id:'',
      photo:{},
      
  };
  setUser(updatedUser);
  setFullName('');
  setEmail('');
  setTitle('');
  setRoleId('');
  setLoginId('');

 

};
  
  const fetchRoles= async () => {
   // console.log('here sir in fetch users ',userData.token)
    const config = { headers: { Authorization: `Bearer ${userData.token}` },};
    try {
      const response = await axios.get('https://www.eldsmw.org:3000/api/user_roles',config);
      const data = response.data;

     console.table('API Response:', data); // Log the response
      setRoles(data)
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch data. Please try again later.',
      };
    }
  };

  const getChangedFields = (original, updated) => {
    const changedFields = {};
        for (const key in updated) {
          if (updated.hasOwnProperty(key) && original[key] !== updated[key]) {changedFields[key] = updated[key];}
        }
        if (original.id) {changedFields.id = original.id; }   // Include id if it exists in original
        return changedFields;
      };  
  const handleImgChange = (event) => {
    const { name, files } = event.target;
    if (name === "img") {
        convertFile(files);
    }
};

const convertFile = (files) => {
    console.log('conviert images')
    if (files && files.length > 0 && files[0] instanceof File) {
        const fileRef = files[0];
        const fileType = fileRef.type;
        const reader = new FileReader();
        reader.readAsBinaryString(fileRef);
        reader.onload = (ev) => {
        const  photo= {data:btoa(ev.target.result),contentType:fileType};
        setDisplayImg(btoa(ev.target.result))
        setImgType(fileType)
        setUser(prevUser => ({...prevUser,photo:photo}));
    };
  }
};

   // Handling input changes
   const handleTextChange = (event) => {
    const { name, value } = event.target;
    setUser(prevUser => {
      const updatedUser = { ...prevUser };
      switch (name) {
        case 'fullName':
          setFullName(value);
          updatedUser['first_name'] = value; // Use 'first_name' as a key
          break;
        case 'email':
          setEmail(value);
          updatedUser['username'] = value; // Use 'last_name' as a key
          break;
        case 'title':
          setTitle(value);
          updatedUser['title'] = value;
          break;
        case 'RoleId':
          setRoleId(value);
          const selectedRole = roles.find(role => role.role === value);
          if (selectedRole) {
            updatedUser['role_id'] = selectedRole.id;
          }
          break;
        default:
          break;
      }
      return updatedUser;
    });
  };

  const handleDelete = () => {
    //setApiMethod('DELETE');
    handleSubmit(null,'DELETE');
};

const handleEdit = () => {
    if (!user.first_name) { toast.error('Please enter First name');return; }
    if (!user.username) { toast.error('Please enter Last name'); return; }
    if (!user.title) {toast.error('Please enter Job title');return; }
    if (!user.role_id) {toast.error('Please enter user role');return; }
    
    handleSubmit(null,'PUT');
};
  


const handleSubmit = async (e, method) => {
    // Required field checks
    const changedFields = getChangedFields(originalUser, user);
    const apiMethod = method;
    setIsLoading(true);
    try {
      const response = await handleApi('users', changedFields, userData, apiMethod);
        console.log('resonse from api sir  sisir ',response)
      if (response.error) {
        // If there's an error, display it
        const errorMessage = response.error.message || 'Error handling request.';
        const statusCode = response.response.status;
        console.log('resonse status  sir ',statusCode)
  
        if (statusCode === 409) {
          toast.error('User already exists', errorMessage);
        } else if (statusCode === 406) {
          toast.error('Cannot delete user because there are products associated with it', errorMessage);
        } else {
          toast.error(`Error ${statusCode}: ${errorMessage}`);
        }
      } else {
        // Check response status
        console.log('here sir',response.response.status);

        if (response.response.status === 200) {
            if (apiMethod=='DELETE'){
                toast.success('User delete successfully.', response.response.statusText);
                onClose();
            }
          toast.success('Userupdated successfully.', response.response.statusText);
          setUserData(response.response.data)
          setUpdateCount(updateCount+1) // to trigger reload  of users list in contextProvider 
        } else {
          toast.error(`Error ${response.response.status}: ${response.response.statusText}`);
        }
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred.');
    } finally {
    //   setActionCount(actionCount + 1); // Trigger reload of products list
      setIsLoading(false);
    }
  };
    



  console.log('user role', userData.user_roles)
  const isUserAuthorized = 
  (userData?.user_roles?.name?.toLowerCase() === 'admin' );

  
  return (
    <>
        <div className="">
            <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">User</h2>
            </div>
            <div>
                {isLoading ? (
                    <div className="flex items-center flex-col">
                        <Audio height="80" width="80" radius="9" color="slate-500" ariaLabel="three-dots-loading" />
                        <p className="mt-2">Processing. Please wait...</p>
                    </div>
                ) : (
                    <>
                        {/* menu icon */}
                        <div className="w-full sm:w-auto sm: px-4 py-2 flex flex-row justify-end">
                            {isUserAuthorized && (
                                <>
                                    <button
                                        className={`bg-transparent mt-3 hover:bg-blue-400 hover:text-white text-blue-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? 'hidden' : ''}`}
                                        title="Edit"
                                        onClick={() => setIsEditable(true)}
                                    >
                                        <CiEdit size={20} color="blue" />
                                    </button>
                                    <button
                                        className={`bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? '' : 'hidden'}`}
                                        title="Delete"
                                        onClick={handleDelete}
                                    >
                                        <FcCancel size={20} color="blue" />
                                    </button>
                                </>
                            )}
                            <button
                                className={`bg-transparent mt-3 hover:bg-blue-400 hover:text-white text-blue-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? '' : 'hidden'}`}
                                title="Save"
                                onClick={handleEdit}
                            >
                                <BsFillSendFill size={20} color="blue" />
                            </button>
                        </div>
                        {/* end menu icons */}

                        <div className="">
                            <ToastContainer position="top-center" autoClose={2000} />
                            <div className="max-w-xl mx-auto">
                                {isLoading && (
                                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                                    </div>
                                )}
                                <div className="w-full">
                                    {/* <form className="shadow-md rounded px-8 pt-4 mb-2"> */}
                                    <div className="relative z-0 mb-6 group">
                                        <input
                                            type="text"
                                            className="block py-1.5 px-0 w-full text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            placeholder=" "
                                            name="fullName"
                                            onChange={(event) => handleTextChange(event)}
                                            value={fullName}
                                            disabled={!isEditable} // Conditionally disable input
                                        />
                                        <label
                                            htmlFor="fullName"
                                            className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                                        >
                                        Full name
                                    </label>
                                </div>

                                <div className="relative z-0 mb-8 group">
                                    <input
                                        type="text"
                                        className="block py-2.5 px-0 w-full text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        placeholder=" "
                                        name="email"
                                        onChange={(event) => handleTextChange(event)}
                                        value={email}
                                        disabled={!isEditable} // Conditionally disable input
                                    />
                                    <label
                                        htmlFor="email"
                                        className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                    Email Address
                                    </label>
                                </div>

                                <div className="relative z-0 mb-8 group">
                                    <input
                                        type="text"
                                        id="title"
                                        className="block py-2.5 px-0 w-full text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        placeholder=" "
                                        name="title"
                                        onChange={(event) => handleTextChange(event)}
                                        value={title}
                                        disabled={!isEditable} // Conditionally disable input
                                    />
                                    <label
                                        htmlFor="title"
                                        className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                        Job Title
                                    </label>
                                </div>
                                <div className="relative z-0 mb-8 group">
                                    <input
                                        type="text"
                                        name="roleId"
                                        className="block py-2.5 px-0 w-full text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        placeholder=" "
                                        list="rolesList" // Link the input to the datalist
                                        onChange={(event) => handleTextChange(event)}
                                        value={roleId}
                                        disabled={!isEditable} // Conditionally disable input
                                    />
                                    <datalist id="rolesList">
                                        {roles && roles.map((role) => (
                                            <option key={role?.role} value={role?.role} />
                                        ))}
                                    </datalist>
                                    <label
                                        htmlFor="page"
                                        className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                        User role 
                                    </label>
                                </div>
                                       
                                <div className="relative z-0 mb-3 w-full group flex items-center">
                                    <input
                                            className="block py-2.5 px-0 w-full text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            type="file"
                                        accept="image/*"
                                        name='img'
                                        onChange={(event) => handleImgChange(event)}
                                        disabled={!isEditable} // Conditionally disable input
                                    />
                                    <label
                                        htmlFor="img"
                                        className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                        Upload User Profile Picture
                                    </label>
                                    {user?.photo_filename ? (
                                        <img
                                        src={user?.photo_filename}
                                        width="55px"
                                        height="55px"
                                        className="rounded-full"
                                        style={{ maxWidth: '75px', maxHeight: '75px' }}  
                                        alt={`${user?.first_name} ${user?.last_name}`} // Added alt attribute for accessibility
                                        />
                                    ) : (
                                        <FaUserLarge
                                        size={55}
                                        color="white"
                                        className="text-gray-400"
                                        />
                                    )}
                                </div>
                                {isEditable && (
                                    <div className="text-center mb-2">
                                        <button
                                            className="px-4 py-2 mb-2 text-sm font-medium text-white bg-slate-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                            onClick={handleEdit}
                                            disabled={!isEditable} // Conditionally disable button
                                        >
                                            Save
                                        </button>
                                    </div>
                                )}
                            {/* </form> */}
                        </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
        <ToastContainer />
    </>
);
}

export default EditUser
