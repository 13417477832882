import React from 'react'
import FileUpload from '../../components/fileupload/FileUpload';


const AddNewsletter = () => {

    const onFileChange = (files) => {
        console.log(files);
    }
  return (
    <div className="box">
        <div className="flex">
        <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Upload Newsletter</h2>
        </div>
        <FileUpload
            onFileChange={(files) => onFileChange(files)}
        />
    </div>
  
  )
}

export default AddNewsletter
