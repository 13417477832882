import React from 'react';
import ReactPlayer from 'react-player';
// import Video from '../images/mary.mp4';
import './story.css';

const Story = () => {
  return (
    <>
     <div className='react-player-container'>
      <ReactPlayer
        controls={true}
        // url={Video} 
        // url='../images/css.mp4'
        url='https://fb.watch/pelxjSq-AX/'
        //  url='https://www.youtube.com/watch?v=7CsX7PwfrJo'
        width='100%'
        height='100%'
      />
     </div>
    </>
  )
}

export default Story