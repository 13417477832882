import React from 'react';
// import { useLocation } from 'react-router-dom';
import './news.css'


const News = ({ image, title, story }) => {
    // const location = useLocation();
    // const { title, info } = location.state;
  return (
    <>
   
    <div className="news__container">
        <div className="news__card">
            <img className="news__img" src={image} alt={title} />
            <div className="news__content">
                <h3 className="news__title">{title} Programme</h3>
                <p className="news__description">{story} </p>
            </div>
        </div>
    </div>
    </>
  );
};

export default News;