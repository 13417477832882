import React from 'react'
import { useState,useRef, useEffect } from "react"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {FcGoogle} from 'react-icons/fc'
import {AiFillFacebook} from 'react-icons/ai'

import Image from '../images/cyclone3.jpg'

import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PartnerImg from '../images/cyclone3.jpg'
import {areasOfPartnership} from '../data'
import {typeofSupport} from '../data'


// import axios from "../api/axios"
import axios from "axios"

export default function Partner() {
    const navigate = useNavigate();
    const location =useLocation();
    const partnerId=location.pathname.split("/")[2];
	// axios.defaults.withCredentials=true;

	//ADMIN
	//USER

    const [items,setItems] =useState([])
    
    // const [newClient,setNewClient] =useState("");
    const [newPname,setNewPname] =useState("");
    const [newPhone,setNewPhone] =useState("");
    const [newEmail,setNewEmail] =useState("");
    const [newCountry,setNewCountry] =useState("");
    const [newArea,setNewArea] =useState(0);
    const [newContribution,setNewContribution] =useState(0);
    
    const [selectedArea, setSelectedArea] = useState(null);
    const [selectedContribution, setSelectedContribution] = useState(null);

    const [currIndex,setCurrIndex] =useState(-1);
    const [message, setMessage] = useState(null);
   
   
    const pname = useRef();
    const phone = useRef();
    const email = useRef();
    const country = useRef();
    const area = useRef();
    const contribution = useRef();

     useEffect(()=>{
        const getPartners= async()=>{
            try{
                const res = await axios.get("http://elds.pcworldmw.com:8081/partners");
                console.log(res); setItems(res.data);    
            }catch(err){
                console.log(err);
                /*if (err.response.status === 404) {
                // // Handle 404 error console.error("Resource not found");
                // } else { // Handle other errors console.error("Error fetching data"); 
               } */
               
            }
        
        }
        getPartners();
        // pname.current.focus();
     },[])
    
    
    const handleClientChange =(event)=>{
		// onChange= {e=>setItems({...items,clientName:e.target.value})}
        setNewPname(event.target.value)
	}
    const handlePhoneChange =(event)=>{
		// onChange= {e=>setItems({...items,clientName:e.target.value})}
        setNewPhone(event.target.value)
	}
    const handleEmailChange =(event)=>{
		// onChange= {e=>setItems({...items,clientName:e.target.value})}
        setNewEmail(event.target.value)
	}

    const handleCountryChange =(event)=>{
		// onChange= {e=>setItems({...items,clientName:e.target.value})}
        setNewCountry(event.target.value)
	}
    

    const handleAreaChange = (selectedOption) => {
        setNewArea(selectedOption);
      };
    
      const handleContributionChange = (selectedOption) => {
        setNewContribution(selectedOption);
      };
	
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (currIndex >= 0) {
          // Handle edit logic
        } else {
          if (
              newPname === "" ||
              newPhone === "" ||
              newEmail === "" ||
              newCountry === "" ||
              !newArea ||
              !newContribution ||
              !newArea.value ||
              !newContribution.value
            ){
              toast.error('Please fill all fields in the form');
          } else {
            try {
              const response = await axios.post("http://localhost:8081/partners", {
                pname: newPname,
                phone: newPhone,
                email: newEmail,
                country: newCountry,
                area: newArea.value,
                contribution: newContribution.value,
              });
      
              setItems([...items, response.data]);
            //   setMessage({ type: "success", text: "Partner created successfully" });
              toast.success('Form submitted successfully. We will come back to you soon');
      
              setNewPname("");
              setNewPhone("");
              setNewEmail("");
              setNewCountry("");
              setNewArea(null); // Reset newArea
              setNewContribution(null); // Reset newContribution
            } catch (err) {
            //   console.log(err);
              setMessage({ type: "error", text: "Error creating partner" });
              toast.error('Error submitting form');

            }
          }
        }
      };


    return (
    <>
    <ToastContainer position="top-center" autoClose={4000} />
    <div className='relative w-full h-screen bg-zinc-900/90'>
        <img className='absolute w-full h-full object-cover mix-blend-overlay' src={PartnerImg} alt="/" />
    

    <div className='flex justify-center items-center h-full'>
        <form onSubmit={handleSubmit} className='max-w-[400px] w-full mx-auto bg-white p-4'>
            <h2 className='text-4xl font-bold text-center py-1'>PARTNER .</h2>
            <p className='text-sm text-gray-500 opacity-50 mb-4'>
                Thank you for choosing to partner with ELDS. Please fill the form  
                and we will get back to you soon.
            </p>
            
            <div className='flex flex-col mb-4'>
                <label>Name or Organisation</label>
                <input 
					className='border relative bg-gray-100 p-2'
					type="text" 
                    //placeholder='Name or Organisation' 
                    ref={pname}
                    value={newPname}
                    onChange= {handleClientChange}
                />
                
            </div>
            <div className='flex flex-col '>
                <label>Telephone</label>
                <input 
                  className='border relative bg-gray-100 p-2'
                  type="text" 
                  ref={phone}
                  value={newPhone}
                  onChange= {handlePhoneChange}
                />
            </div>
            <div className='flex flex-col '>
                <label>Email</label>
                <input 
                  className='border relative bg-gray-100 p-2'
                  type="text" 
                  ref={email}
                  value={newEmail}
                  onChange= {handleEmailChange}
                />
            </div>
            <div className='flex flex-col '>
                <label>Country</label>
                <input 
                  className='border relative bg-gray-100 p-2'
                  type="text" 
                  ref={country}
                  value={newCountry}
                  onChange= {handleCountryChange}
                />
            </div>

            <div className='flex flex-col '>
                <label>Prefered Area ?</label>
                <Select
                  className='border relative bg-gray-100 p-2'
                  value={newArea}
                  onChange={handleAreaChange}
                  options={areasOfPartnership}
                 
                />
            </div>

            <div className='flex flex-col '>
                <label>Contribution type  ?</label>
                <Select
                  className='border relative bg-gray-100 p-2'
                  value={newContribution}
                  onChange={handleContributionChange}
                  options={typeofSupport}
                 
                />
            </div>
            <button className='w-full py-2 mt-4 bg-blue-500 hover:bg-indigo-400 relative text-white'>Sign In</button>
            
        </form>
    </div>
    </div>
    </>
  )
}

