//ImgActionDialog
import React from 'react';


const ImgActionDialog = ({ onImgEdit, onImgDelete, onImgCancel, onOpenEditImage, imageId, open }) => {
  
  // const handleEditClick = () => {
  //   onOpenEditImage(imageId); // Call onOpenEditImage with imageId
  // };

  
  

  return (
    <div className="modal-content max-w-4xl w-full p-8 bg-gray-700 text-white text-center">
      <h2 className="text-3xl font-bold mb-6 border-b">Image Action</h2>
      <p className="text-lg mb-6">What do you want to do with the image ?:</p>
      <div className="modal-buttons flex space-x-6">
          <button
            className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
            onClick={onImgEdit} 
          >
           Edit
          </button>         

          <button
            className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
            onClick={onImgDelete} 
          >
            Delete
          </button> 

          <button
            className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
            onClick={onImgCancel} 
          >
            Cancel
          </button>         
       
       

        
        
      </div>
    </div>
  );
};

export default ImgActionDialog;
