// CategoryService.js
import axios from 'axios';
import API_BASE_URL from '../config';




export const handleApi = async (page, changedFields, userData, apiMethod) => {
  console.log('here sir in api sir api in handleCategory api ',userData.token);
  const config = {
    headers: { Authorization: `Bearer ${userData.token}` },
    params: changedFields.id ? { id: changedFields.id } : {}, // Send profile.id as a query parameter if present
  };
  
  try {
    let response;
    switch (apiMethod) {
      case 'PUT':
        console.log('config soir ',changedFields);
        response = await axios.put(`${API_BASE_URL}/${page}`, changedFields, config);
        break;
      case 'POST':
        response = await axios.post(`${API_BASE_URL}/${page}`, changedFields, config);
        break;
      case 'DELETE':
        response = await axios.delete(`${API_BASE_URL}/${page}`, config);
        break;
      default:
        throw new Error('Unsupported method');
    }
    console.log('from api response sir ', response);
    return {response }; // Return response data
  } catch (err) {
    console.error(`Error handling category API:`, err.response ? err.response.data : err.message);
    return { 
      error: {
        message: err.response ? err.response.data.message : err.message,
        status: err.response ? err.response.status : null
      }
    }; // Return error details including status code
  }
};

