import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Audio } from 'react-loader-spinner';
import axios, { all } from 'axios';


const ReadMail = ({ rowData }) => {
// const [mail, setMail] = useState([{ name: '',  title: '', description:'',id:'', parent:'' ,userId:0 ,img:'',imgType:'',displayImg:'',imgUrl:'',}]);
const [mail, setMail] = useState({});


const [isLoading,setIsLoading]=useState(false);
const { userData, mails,updateCount,setUpdateCount} = useContext(AppContext);

useEffect(() => {

  setMail(rowData)
  console.log('api mail sir ',rowData)
},[rowData]);


  const ClearMail = () => {// clear form field 
    setMail({mailName: '',title: '',description: '',id:'',parentMail: '',userId: '',img: '',imgType: '',displayImg:'',imgUrl:'',});
  };

  const getInitials = (name) => {
    if (!name) return ""; // Return an empty string if name is undefined
    return name.split(' ')
      .map(word => word.charAt(0))
      .join('')
      .toUpperCase();
  };
  

  //: "https:/www.eldsmw.org:3000/api/photos/home.jpg";

console.log('mail url  at this point ',mail);
  return (


    <div className=""style={{ width: "100%" }}>
           
      <div className="flex">
      <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Mail</h2>
  
      </div>
  
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="w-full max-w-full">
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <Audio type="Bars" color="#FFF" height={50} width={50} />
          </div>
        )} 

        






        <div className="flex flex-col my-4">
            <div id="subject" className="w-full my-2  p-4 md:p-4 bg-gray-50 rounded-3xl">
                <h2 className="font-bold text-center text-sm uppercase" style={{ textTransform: 'capitalize' }}>{mail.subject}</h2>
            </div>

            <div className={`flex items-center p-1  rounded-lg `}>
                <div className="flex-shrink-0 w-12 h-12 rounded-full bg-blue-500 flex items-center text-white justify-center text-xl">
                    {getInitials(mail.surname)}
                </div>
                <div className="ml-4 flex-grow">
                    <div className="flex gap-2 items-center">
                        <div className={`text-sm text-black font-semibold w-[150px] `}>{mail.surname}</div>     
                        <div className="text-xs text-black ml-auto text-right">{mail.create_date}</div>
                    </div>
                </div>
            </div>

            <div id="content" className="w-full my-4 p-4 md:p-4 bg-gray-50 rounded-3xl">
                <p className="text-sm leading-tight"dangerouslySetInnerHTML={{ __html:mail.content }}></p>   
            </div>
        </div>

        {/* <form className={`bg-${bgColor} shadow-md rounded px-8 pt-6 mb-4`}> */}
     
    <div className="mb-4 ">
<button
  className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
  
  
>
  Reply
</button>         
        </div>
    </div>
    </div>
  
  )
}

export default ReadMail
