// import {SiOpenaigym} from 'react-icons/si'



export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "About",
        path: '/about'
    },
    {
        name: "Gallery",
        path: '/gallery'
    },
    {
        name: "Plans",
        path: '/plans'
    },
    {
        name: "Trainers",
        path: '/trainers'
    },
    {
        name: "Contact",
        path: '/contact'
    }
]





export const typeofSupport = [
     
    
    { value: '1', label: 'Financial Contribution' },
    { value: '2', label: 'Material Contribution' },
    { value: '3', label: 'Service Contribution' },
];

export const areasOfPartnership = [
    
    { value: 1, label: 'Climate Change' },
    { value: 2, label: 'Food and Nutritional Security' },
    { value: 3, label: 'Emergency and Relief' },
    { value: 4, label: 'Micro finance' },
    { value: 5, label: 'HIV/AIDS' },
    { value: 6, label: 'Human Rights' },
];

export const programs = [
    {
        id: 1,
        icon: require("./images/foodsecurity.jpg"),
        title: "Food and Nutrition Security",
        info: "Immediate food access but also the underlying factors contributing to poverty and malnutrition",
        story: `Malawi faces a host of food-related challenges. These include 
                chronic food insecurity among poor and vulnerable households; 
                recurrence of natural disasters such as droughts and floods; high cost
                of living; high prevalence of chronic malnutrition and widespread 
                micronutrient deficiencies; and low income for smallholder farmers due
                to poor agricultural market structures and policies.Strategic Goal: 
                To increase food production and availability; improve nutrition 
                status at household level.Strategies:(i) Promote crop and livestock 
                diversification among smallholder farmers(ii) Encourage use of hybrid 
                or certified seeds for improved agricultural productivity(iii) Promote
                irrigation farming as an alternative to rain-fed agriculture
                (iv) Reduce post-harvest losses through improved conditions of 
                the storage facilities(v) Promote nutrition among the communities(vi) 
                Conduct food processing, storage and utilization awareness
                (vii) Promote food budgeting among the smallholder farmers
                (viii) Promote integrated aquaculture agriculture among smallholder 
                farmersEvidence of Change:(i) Number of crops grown by a household
                (ii) Number of households with livestock(iii) Increased yields per unit
                area(iv) Increase in number of households in irrigation farming
                (v) Number of households using improved commodity storage 
                facilities(vi) Behavioural change in nutrition issues`,
        path: "/programs/foodnnutrition"
       

    },
    {
        id: 2,
        icon: require("./images/microfinance.jpg"),
        title: "Micro Finance",
        info: "ELDS Microfinance program strives to empower low-income and vulnerable populations to improve their economic well-being and alleviate poverty. In the photo, Mrs. Rose Korokhwa proudly plying her grocery business courtesy of ACT Alliance .",
        story: `In Malawi, access to credit, savings opportunities and other financial services 
                remains one of the major constraints to enterprise development. This is more so among
                smallholder farmers who form 96.4% of all micro, small and medium enterprises and in
                particular those owned by women. The formal financial sector does not cater for these
                enterprises because they do not have collateral and security guarantees. They are also
                believed to be high-risk clients who are unable to repay loans and the cost of delivering
                credit and saving services to them is said to be very high. Strategic Goal: To improve 
                income-earning opportunities at household level.Strategies:(i) Promote Village Savings
                and Loans Associations (VSLAs)(ii) Promote small scale businesses(iii) Promote self-help
                groups among communities. Evidence of Change:(i) Number of VSLAs(ii) Number of households
                venturing in micro enterprises (iii) Number of self-help groups(iv) Income levels among 
                the participating households`,
        path: "/programs/foodnnutrition"
    },
    {
        id: 3,
        icon: require("./images/hygiene.jpg"),
        title: "Sanitation and Hygiene",
        info: "ELDS works in various underserved or vulnerable communities to improving access to clean water, sanitation facilities, and hygiene education. under this project we aim at promoting public health, reducing waterborne diseases, and enhancing the overall well-being of communities in need.",
        story: `Ensuring that children and women have access to safe water and appropriate sanitation
                and that they learn healthy hygiene practices is a big determinant of child survival
                and development. With water-borne related diseases being among the major causes of 
                death in young children in Malawi, providing safe water and improved sanitation and 
                hygiene takes on urgent dimensions. Malawi has made significant progress in increasing 
                access to safe water and sanitation. According to the 2020 SDG VNR report , the number
                of households with access to improved water sources has increased significantly from 47.0
                percent in 1990 to 90.0 percent in 2016 exceeding the Millennium Development Goal target
                of 74.0 percent. Safely managed water supply is the source, which is located on premises,
                available when, needed and free of feacal contamination. In Malawi, about 67.0 percent 
                of the population are using basic services of water and 20 percent limited services. 
                However, good hygiene practices are rather low with only 37 per cent of the population 
                practicing better hygiene. Only 10 percent of households have a basic hand washing
                facility in Malawi and it is priority indicator for global monitoring of hygiene under
                the SDGs. In Malawi 42 percent were using safely managed sanitation services and only 6 
                percent continued to practice open defecation. Malawi adopted the Community Led Total 
                Sanitation (CLTS) in 2008 to make country Open Defecation Free (ODF) by promoting 
                sanitation in the communities. Effective provision of safe water is compromised by 
                frequent breakdowns of water points. One-third of community water points are not 
                operational at any point in time due to these frequent breakdowns. Approximately 20 to 
                25 per cent of schools have no protected water supply and on average, there is one school
                latrine for every 150 pupils. Poor hygiene, lack of sanitation and low quantity 
                and quality of drinking water all contribute to Malawi’s poor health indicators for 
                mothers and children, and negatively affect their livelihood.Strategic Goal: To 
                contribute to improvements of vulnerable peoples’ health and child education through
                increased and sustainable access to WASH servicesStrategies:(i) Create awareness in 
                communities and schools about WASH (ii) Provide sustainable and equitable safe water 
                sources and sanitation facilities in communities and schools(iii) Develop and promote 
                replicable sanitation and hygiene approaches(iv) Support district and national WASH
                governance structures (v) Support capacity building of local structures involved in
                delivery and management of WASH services(vi) Promote hygienic behaviour culture among 
                communities. Evidence of Change: (i) Behavioural change towards WASH(ii) Number of 
                communities with WASH facilities (iii) Number of schools with WASH facilities (iv) 
                Reduced incidences of water borne related illnesses`,
        path: "/programs/foodnnutrition"
    },
    

    {
        id: 4,
        icon: require("./images/climate.jpg"),
        title: "Climate Change",
        info: "ELDS engages a number of projects and initiatives  in selected rural communities to to mitigate the impacts of climate change, adapt to changing conditions, and raise awareness about environmental issues.",
        story: `Malawi is heavily dependent on natural resources, mainly soils, water, fisheries 
                from inland lakes and fuel wood from forests. These climatic changes pose a great threat 
                to a large number of people’s livelihoods. Malawi has witness an increase in disasters 
                related to climate change, like floods and drought over the years. For example, over the
                last decades the Shire Valley in southern Malawi, has experienced some of the worst 
                droughts (1991/92) and floods (2000/01) in living memory as well as the recent floods 
                of 2019. Floods have resulted in severe crop loss, infrastructure destruction, including
                roads and the only rail line that links the south to the centre, resulting in serious
                socio-economic disruptions, food insecurity, and diseases, such as diarrhoea, cholera
                and malaria. Malawi is an already severely poor country facing an AIDS pandemic, chronic
                malnutrition, declining soil fertility, shortages of land and inadequate agricultural 
                policies. About 6.3 million Malawians live below the poverty line, the majority in rural 
                areas, with more than 90% relying on rain-fed subsistence farming to survive. Evidence 
                strongly suggests that increased droughts and floods may be exacerbating poverty levels, 
                leaving many rural farmers trapped in a cycle of poverty and vulnerability. The country 
                has experienced a number of adverse climatic hazards over the last several decades. The 
                most serious have been dry spells, seasonal droughts, intense rainfall, riverine floods 
                and flush floods. Some of these, especially droughts and floods, have increased in 
                frequency, intensity and magnitude over the last two decades, and have adversely 
                impacted on food and water security, water quality, energy and the sustainable livelihoods
                of rural communities. Farmers are increasingly concerned about the impact of climate change
                on agriculture and food security. The degradation of the environment through poor land use
                and deforestation is a serious concern for people in the coun`,
        path: "/programs/foodnnutrition"
    }
]








export const values = [
    {
        id: 1,
        //  icon: <SiOpenaigym/>,
         icon: require("./images/cycrone1.jpg"),
        // icon: <AiOutlineHeart/>,
        // title: "Love",
        title: "",
        desc: "ELDS joined other Actalliance Malawi Forum members to donate assorted relief items worth 10 million kwacha to Cyclone Freddy victims at Mpala Camp in Mulanje District."
    },
    {
        id: 2,
        // icon: <SiOpenaigym/>,
        icon: require("./images/cyclone2.jpg"),
        title: "",
        desc: "On 29 May 2023  launched a six months Emergency Response to Cyclone Freddy  to support  400 vulnerable households in Zomba district affected by Tropical Cyclone Freddy with immediate food aid, livelihoods recovery and WASH interventions."
    },
    {
        id: 3,
        icon: require("./images/cyclone3.jpg"),
        title: "",
        desc: "ELDS  engaged  beneficiaries of the Emergency Response   in Chikwawa District in an 8 months project to restore livelihood in 350 vulnerable  households of TA  Masache and Ngabu in Chikhwawa District, ."
    },
    {
        id: 4,
        icon: require("./images/cyclone4.jpg"),
        title: "",
        desc: "Leader of Evangelical Lutheran Church in Malawi, Bishop Dr Joseph P Bvumbwe says that most of the disasters happening in Malawi are self inflicted and advised members of the church to plant more trees to avoid perennial flooding in the country."
    }
]









export const faqs = [
    {
        id: 1,
        question: "How often should I exercise?",
        answer: "Consectetur adipisicing elit. Non ipsa dolorem, rem consequatur eum omnis ex, fuga temporibus qui nesciunt odio aliquam commodi culpa inventore ut similique repellendus perferendis sint!"
    },
    {
        id: 2,
        question: "What time of day is best to work out?",
        answer: "Distinctio nihil blanditiis accusantium atque, quo maxime inventore eum! Cum dolorem quibusdam amet et qui. Eos, omnis beatae? Quas, est at! Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit."
    },
    {
        id: 3,
        question: "How long should my workouts be?",
        answer: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
    },
    {
        id: 4,
        question: "Do I need to warm up before my workouts?",
        answer: "Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit, consequatur quibusdam dignissimos cum labore possimus placeat consectetur nisi cupiditate? Qui totam est omnis dolor nobis quisquam veritatis a!"
    },
    {
        id: 5,
        question: "Should I do strength training, cardio or both?",
        answer: "Maiores fuga, cum praesentium esse laudantium! Distinctio nihil blanditiis accusantium atque, quo maxime inventore eum! Cum dolorem quibusdam amet et qui."
    },
    {
        id: 6,
        question: "Should I lift weights for strength training?",
        answer: "Quas, est at! Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit, consequatur quibusdam dignissimos cum labore possimus placeat consectetur nisi cupiditate."
    }
]








export const testimonials = [
    {
        id: 1,
        name: "Mr. Moffat Chatengwa",
        quote: "Thanks ELDS, I have a striving vegetable patch, courtesy of Evangelical Lutheran Mission in Lower Saxony ",
        job: "Vegetable farmer -  T/A Mwambo , Zomba",
        avatar: require("./images/testimonial1.jpg")
    },
    {
        id: 2,
        name: "Mrs. Rose Korokhwa",
        quote: " I survived Cyclone Idai of 2019 and ELDS gave me  the opportunity to invest in a  grocery business courtesy of ACT Alliance. Now am self reliant. Thank you ELDS. ",
        job: "Microfinance beneficiary - Phalombe District",
        avatar: require("./images/testimonial2.jpg")
    },
    
    
    
]







const Project1 = require('./images/project1.jpg')
const Project2 = require('./images/project2.jpg')
const Project3 = require('./images/project3.jpg')
const Project4 = require('./images/project4.jpg')
const Project5 = require('./images/project5.jpg')
//const Project6 = require('./images/project6.jpg')


export const projects = [
    {
        id: 1,
        image: Project1,
        title: 'Sexual Reproductive Health Rights-HIV/AIDS',
        content: 'Build capacity of local structures in SRHR modalities and HIV/AIDS prevention, treatment, care and support',
         socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 2,
        image: Project2,
        title: 'Micro Finance',
        content: 'To improve income-earning opportunities at household level by promoting village Savings and Loans Associations (VSLAs),promote small scale businesses, and promoting self-help groups among communities',
         socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 3,
        image: Project3,
        title: 'Food and Nutrition Security',
        content: 'To increase food production, availability  and  nutrition status at household level by promote crop and livestock diversification among smallholder farmers, encouraging use of hybrid or certified seeds for improved agricultural productivity and  promote irrigation farming as an alternative',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 4,
        image: Project4,
        title: 'Climate Change Adapatation and Disaster Management',
        content: 'Farmers are increasingly concerned about the impact of climate change on agriculture and food security. The degradation of the environment through poor land use and deforestation is a serious concern for people in the country. Farmers know that these activities result in more floods and droughts but have failed to stop, as there are few alternatives. ',
         socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 5,
        image: Project5,
        title: 'Emergency and Relief',
        content: 'To save lives of the affected people during disasters by building institutional capacity in emergency and relief,building capacity of local structures in emergency and relief as well as  establishing district rapid response teams for emergencies.',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 6,
        image: Project5,
        title: 'Human Rights, Gender, Advocacy and Cross Cutting Issues',
        content: 'To build capacity of right holders to know their rights and claim them. by createing and promoting awareness among communities on human rights issues, building capacity of local structures in human rights issues and establishing community based human rights committees',
         socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    }
]