import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../../contexts/AppContext';
import {BsInstagram} from 'react-icons/bs'
import {AiOutlineTwitter} from 'react-icons/ai'
import {FaFacebookF} from 'react-icons/fa'
import {FaLinkedinIn} from 'react-icons/fa'
import axios, { all } from 'axios';


import Header from '../../components/Header'
import HeaderImage from '../../images/project.jpg'
//import {projects} from '../../data';
import Project from '../../components/Project'
import Footer from '../../components/Footer';
import './projects.css';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState([]);


  

  async function fetchPage() {
   
    const config = {
      // headers: { Authorization: `Bearer ${userData.token}` },
      params: { name:'projects'}, // query parameters 
    };
    try {
        const response = await axios.get('https://www.eldsmw.org:3000/api/pages', config);
        const data = response.data;
        console.table('page API Response sir :', data);
        const page= data[0];
        const pageArticles = data[0].pageArticles; // Assuming there's only one object in the array
        // Set the extracted pageArticles to the projects state
        setProjects(pageArticles);
        setPage(page)
    } catch (error) {
        console.error('Error fetching data:', error);
        return { count: 0, result: null, error: 'Failed to fetch data. Please try again later.', };
    }
}

useEffect(() => {
  fetchPage(); // Call the async function within useEffect
  // console.log('projects',projects)
  //fetchArticles(); // Call the async function within useEffect
}, []);


  
const photoUrl = page.pagePhotos?.[0]?.photoUrl;
  
  return (
    <>
    <Header title={page.title || ''} description={page.description || ''}
      imgUrl={photoUrl}
    />
      <section className="projects">
        <div className="container projects__container">
          {
            projects.map(({id,articlePhotos,title,content,})=>{
          
              return <Project key={id} images={articlePhotos} title={title}content={content}
              /*socials={
                [
                  {icon:<BsInstagram/>,link:socials[0]},
                  {icon:<AiOutlineTwitter/>,link:socials[1]},
                  {icon:<FaFacebookF/>,link:socials[2]},
                  {icon:<FaLinkedinIn/>,link:socials[3]}
            
                ] }*/
              />
            })
          }
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default Projects
