import { useEffect } from 'react';

function TabBar({ activeTab, setActiveTab }) {
  const tabs = [
    { name: 'Dashboard', key: 'dashboard' },
    { name: 'Users', key: 'user' },
    { name: 'Pages', key: 'pages' },
    { name: 'Articles', key: 'article' },
    { name: 'Images', key: 'images' },
    { name: 'Enquiries', key: 'enquiry' },
    { name: 'Newsletters', key: 'newsletter' },
    { name: 'Testimonials', key: 'testimonial' }
  ];

  // Set the active tab to 'dashboard' when the component mounts
  useEffect(() => {
    setActiveTab('dashboard');
  }, []); // Empty dependency array to run only once on mount

  return (
    <div className="flex justify-center mb-2 w-full">
      <div className="" style={{ display: 'flex', flexDirection: 'row' }}> {/* Inline styles added */}
        {tabs.map(tab => (
          <button
            key={tab.key}
            className={`py-2 px-4 ${activeTab === tab.key ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} ${tab.key === 'dashboard' ? 'rounded-l' : tab.key === 'enquiry' ? 'rounded-r' : ''}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.name}
          </button>
        ))}
      </div>
    </div>
  );
  
}

export default TabBar;
