import React, { useState, useEffect } from "react";
import axios from 'axios';
import { FaCrown } from "react-icons/fa";
import SectionHead from "../../components/SectionHead";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './about.css';

const About = () => {
  const [page, setPage] = useState({});
  const [pageArticles, setPageArticles] = useState([]); // State to hold pageArticles

  useEffect(() => {
    fetchPage();
  }, []);

  async function fetchPage() {
    const config = {
      params: { name: 'about' }, // query parameters 
    };
    try {
      const response = await axios.get('https://www.eldsmw.org:3000/api/pages', config);
      const data = response.data;
      console.table('page API Response:', data);
      const page = data[0] || {}; // Ensure page is initialized correctly
      const articles = page.pageArticles || []; // Extract pageArticles from page
      setPage(page);
      setPageArticles(articles); // Set pageArticles state
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const photoUrl = page.pagePhotos?.[0]?.photoUrl;
  console.log('articles at this point',page.pageArticles)


  return (
    <>
      <Header
        title={page.title || ''}
        description={page.description || ''}
        imgUrl={photoUrl}
      />
      {pageArticles.map((article) => (
        <div key={article.id}>
          <section className="about__story">
            <div className="container about__story-container mx-auto">
              <div className="about__section-image">
                {/* Assuming article.photos exists and is an array */}
                <img src={article.articlePhotos?.[0]?.photoUrl} alt="Story" />
              </div>
              <div className="about__section-content">
                <SectionHead icon={<FaCrown />} title={article.title || ''} />
                {/* Render article content with dangerouslySetInnerHTML */}
                <p className="text-justify" dangerouslySetInnerHTML={{ __html: article.content || '' }}></p>
              </div>
            </div>
          </section>
        </div>
      ))}
        <Footer/> 
    </>
  );
};

export default About;
