

import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app.css';
import Home from  './pages/home/Home'
//import Home from './components/pages/home/Home';
import About from './pages/about/About';
import Projects from './pages/projects/Projects';
import Partners from './pages/partners/Partners';
import Contact from './pages/contact/Contact';
import Signin from './pages/signup/Signin';
import Ourstory from './pages/story/Ourstory';
import PhotoGallery from './pages/gallery/PhotoGallery';
import Faqs from './pages/faqs/Faqs';
import LoginForm from './pages/LoginForm';
import Dashboard from './pages/dashboard/Dashboard';
import FoodnNutrition from './pages/programs/FoodnNutrition';
import Footer from './components/Footer'
import ContextProvider from './contexts/ContextProvider';

function App() {
  return (
    <>
     <ContextProvider>
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact  Component={Home}/>
        <Route path='/about' Component={About} /> 
        <Route path='/partners' Component={Partners} /> 
        <Route path='/projects' Component={Projects} />
        <Route path='/contact' Component={Contact} /> 
        <Route path='/signin' Component={Signin} /> 
        <Route path='/ourstory' Component={Ourstory} /> 
        <Route path='/photogallery' Component={PhotoGallery} /> 
        <Route path='/faqs' Component={Faqs} /> 
         {/* <Route path='/sign-up' Component={LoginForm} />  */}
        <Route path='/dashboard' Component={Dashboard} />
        <Route path='/programs/foodnnutrition' Component={FoodnNutrition} />
      </Routes>
       {/* <Footer/>  */}
    </Router>
    </ContextProvider>
  </>
  );
}

export default App;

