import React from "react";
import { IoClose } from "react-icons/io5";

const Modal = ({ open, onClose, children, resetPos, minWidth }) => {
  const handleCloseModal = () => {
    onClose();
    if (resetPos) {resetPos();}
  };

  return (
    <div
    
    
      className={`fixed inset-0 flex justify-center items-center transition-opacity ${
        open ? "visible bg-black/20" : "invisible"
      }`}
      style={{
        display: open ? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
      }}
    >
      <div
        className="bg-white rounded-xl shadow p-6"
        style={{
          position: "relative",
          maxWidth: "90%",
          maxHeight: "90%",
          overflow: "auto",
          minWidth: minWidth || "200px", // Set a default minWidth or use the provided minWidth prop
       
        }}
      >
        
        <button
          onClick={handleCloseModal}
          className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
        >
              <IoClose size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
