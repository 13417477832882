// Home.jsx
import React, { useState, useEffect, useContext } from "react";
import AppContext from '../../contexts/AppContext';
import axios from 'axios';
import HeroSection from '../../components/Herosection';
import FrontNews from "../../components/frontnews/FrontNews";
import Newsletter from '../../components/Newsletter';
import Testimonial from '../../components/testimonial/Testimonial';
import Footer from '../../components/Footer';

function Home() {
  const { userData, setUserData } = useContext(AppContext);
  const [page, setPage] = useState([]);

  useEffect(() => {
    setUserData(prevUser => {
      const updatedUser = { ...prevUser, isLoggedIn: false };
      return updatedUser;
    });
  }, []);

  useEffect(() => {
    fetchPage();
  }, []);

  async function fetchPage() {
    const config = { params: { name: 'home' } };
    try {
      const response = await axios.get('https://www.eldsmw.org:3000/api/pages', config);
      const data = response.data;
      setPage(data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const photoUrl = page.pagePhotos?.[0]?.photoUrl;

  return (
    <>
      <HeroSection title={page.title} description={page.description} imgUrl={photoUrl} />
      <FrontNews articles={page?.pageArticles || []} />
      <Newsletter />
      <Testimonial />
      <Footer />
    </>
  );
}

export default Home;
