import Partner from '../../components/Partner';
// import '../../../app.css';



const Partners = () => {
  return (
  <>

    <section className="login">
       <div className=" partners__container">
         <div className="partners__wrapper">
           <Partner />
         </div>
       </div>
    </section> 
     </>
  )
}

export default Partners
