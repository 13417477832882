import Card from './ui/Card';
import './project.css';

const Project = ({images,title,content}) => {
console.log('images in project',images,title,content)
  return (
    <Card className="project">
      {images && images.map((img, index) => (
        <div className="project__img" key={index}>
          {images && (<img src={images[0].photoUrl} alt={title} />)}
        </div>
      ))}
      {title && (<h2>{title}</h2>)}
      {content && (<p dangerouslySetInnerHTML={{ __html: content }} />)}
    </Card>
  )
}

export default Project