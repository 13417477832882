
import React, { useState, useEffect, useContext  } from "react";
import AppContext from '../contexts/AppContext';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './navbar.css';
import logoImage from '../images/logo5.png'; // Replace with the actual path to your logo file


const Navbar=()=> {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const { userData, setUserData} = useContext(AppContext);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
      if (window.innerWidth <= 960) {
        setButton(false);
      } else {
        setButton(true);
      }
    };
  
     useEffect(() => {
      showButton();
     }, []);

    const handleSignOut = () => {
      closeMobileMenu(); // Close the mobile menu
      // Clear user data when signing out
      setUserData(prevUser => {
        const updatedUser = { ...prevUser };
        updatedUser.isLoggedIn= false;
        updatedUser.username= '';
        updatedUser.token= '';
        updatedUser.img= '';
        updatedUser.session= '';
        updatedUser.role= '';
        updatedUser.email= '';
      })
      // Additional logic to clear authentication token or user data from local storage if needed
    };
  
    window.addEventListener('resize', showButton);
    // console.log("IsLoggedIn in nav bar :", userData.isLoggedIn);
  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            ELDS
            {/* <i class='fab fa-typo3' /> */}
            <img src={logoImage} alt='Logo' className='logo-image' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div> 
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        {!userData.isLoggedIn ? (
                            <li className='nav-item'>
                                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                    Home
                                </Link>
                            </li>
                        ) : null}
                        {!userData.isLoggedIn ? (
                            <li className='nav-item'>
                                <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                                    About us
                                </Link>
                            </li>
                        ) : null}
                        {!userData.isLoggedIn ? (
                            <li className='nav-item'>
                                <Link to='/projects' className='nav-links' onClick={closeMobileMenu}>
                                    Our Projects
                                </Link>
                            </li>
                        ) : null}
                        {!userData.isLoggedIn ? (
                            <li className='nav-item'>
                                <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                                    Contact us
                                </Link>
                            </li>
                        ) : null}
                        <li className='nav-item'>
                            {userData.isLoggedIn ? (
                                <Link
                                    to='/'
                                    // className='flex items-center rounded-md p-1 mt-5 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500 '
                                    
                                    className="flex items-center rounded-md p-1 mt-5  text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                                    //onClick={handleSignOut}
                                >
                                    Sign Out
                                </Link>
                            ) : (
                                <Link
                                    to='/signin'
                                    className="flex items-center rounded-md p-1 mt-5  text-white bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 hover:text-white from-cyan-500 to-blue-500"
                                 
                                    // className='flex items-center rounded-md p-1 mt-5 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500 '
                                    onClick={closeMobileMenu}
                                >
                                    Sign In
                                </Link>
                            )}
                        </li>
                    </ul>
        
        </div>
      </nav>
    </>
  )
}

export default Navbar
