// HeroSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import './herosection.css';

const HeroSection = ({ imgUrl, title, description }) => {
const processedTitle = title ? title.toUpperCase() : '';
const stripHtml = (html) => {
  return (html || '').replace(/<[^>]*>/g, ''); // Ensures html is a string
};

const strippedDescription = stripHtml(description);




  return (
   
      <div 
        className='hero__container relative z-10 flex flex-col justify-center items-center bg-black bg-opacity-50 py-20'
        style={{
          backgroundImage: `url(${imgUrl})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat'
        }}
      >
        {processedTitle && <h1 className="text-white text-4xl md:text-5xl lg:text-6xl">{processedTitle}</h1>}
        {description && (
           <span className="text-white text-2xl md:text-3xl lg:text-2xl mt-4" >{strippedDescription }  </span>
 // <h1 className="text-white text-4xl md:text-5xl lg:text-6xl"dangerouslySetInnerHTML={{ __html: description }}></p>
       )}
        <div className="flex hero-btns mt-8">
          <Link to='/ourstory'>
            <button className='btns btn--primary btn--large'>
              WATCH OUR STORY <i className='far fa-play-circle'></i>
            </button>
          </Link>
          <Link to='/photogallery'>
            <button className='btns btn--primary btn--large'>
              PHOTO GALLERY <i className='far fa-play-circle'></i>
            </button>
          </Link>
        </div>
      </div>
    
  );
}

export default HeroSection;
