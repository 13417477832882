import React from 'react'
import { Collapse } from 'antd';
import './faq.css'
const { Panel } = Collapse;

const Faq = () => {
  
  return (
    <div className='block faqPage max-w-500 mx-auto'>
   
    <div className='container  '>
      <h1  className="text-center text-blue-600 font-bold">FREQUENTLY ASKED  QUESTIONS</h1>
      <Collapse accordion defaultActiveKey={['1']} className="list-disc">
      <Panel header="Do Malawi Government and other Authorities recognize ELDS?" key="1"className='custom-panel' >
    
    <p className=''>ELDS is a development service arm of the Evangelical Lutheran Church in Malawi (ELCM), registered as non-governmental organisation with CONGOMA and recognized partner of Malawi Government. Internationally, ELDS is an active member of Global Act alliance and locally a member of CISANET, CISONEC, MVAC and WESNET.</p>
  </Panel>
  <Panel header="Where do we work?" key="2">
    <p>ELDS has project presence in 13 districts (Chitipa, Karonga, Mzimba, Nkhota-kota, Kasungu, Mchinji, Dowa, Lilongwe Rural, Dedza, Zomba, Phalombe, Chikwawa, Mwanza/Neno) across Malawi.</p>
  </Panel>
  <Panel header="Where does ELDS get funding/ our donors?" key="3"className='custom-panel'>
    <p>Some of our donors include Bread for the World, OXFAM, Evangelical Lutheran Mission (ELM), and Evangelical Lutheran Church in America (ELCA), UN-FAO, and Scottish Government through DAI Global, IOM and US Embassy Department of Defence.
</p>
  </Panel>
  <Panel header="How long has ELDS been in operation?" key="4">
    <p>ELDS was founded in 1989 in response to the needs of Mozambican refugees displaced to Malawi.</p>
  </Panel>
  <Panel header="Is being or becoming a member of Evangelical Lutheran Church in Malawi a prerequisite for receiving help from ELDS?" key="5" className='custom-panel'>
    <p>Our policy states that we will not select partners or beneficiaries on this basis, nor use aid to induce a person to change religion.</p>
  </Panel>
  <Panel header="Do people living in project communities contribute to ELDS projects?" key="6">
    <p>We always engage existing local structures and communities to contribute to our development projects at all levels of project implementation. This enhances a real sense of ownership and true participation, which is good for sustainability of projects.</p>
  </Panel>
  <Panel header="What are areas of expertise for ELDS?" key="7" className='custom-panel'>
    <p>ELDS has for the past years been actively involved in Food and Nutrition Security, Livelihoods, WASH and Health initiatives programming. Furthermore, ELDS has expertise in Emergency Response that include resilience building, disaster risk management and climate change programming</p>
  </Panel>
  <Panel header="What are our experiences with institutional and other donors?" key="7" className=''>
    <p>ELDS has expertise in working partnerships and consortia and has very good working relationship with local structures in the areas it is working, as well as the district councils in the districts of operation. ELDS has good experience in grant management and has worked with donors such as DFID through Water Aid, USAID,UN through WFP, FAO, IOM, and  Bread for the World. ELDS has some systems in place that have been developed based on intentional standards of the Lutheran World Federation in Geneva that emphasize on good practice in finance, compliance and audit, risk management, human resources, logistics, that supports project implementation.</p>
  </Panel>
      </Collapse>
      <style jsx>{`
          .custom-collapse .ant-collapse-item .ant-collapse-header::before {
            color: #3490dc !important; /* Replace with your desired blue color */
          }
        `}</style>
     

    </div>
  </div>
  )
}

export default Faq
