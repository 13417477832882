import React from 'react';

const DeleteConfirmationDialog = ({ onCancel, onPermanentDelete, onDisable }) => {
  return (
    <div className="modal-content max-w-4xl w-full p-8 bg-gray-700 text-white text-center">
      <h2 className="text-3xl font-bold mb-6 border-b">Delete Confirmation</h2>
      <p className="text-lg mb-6">Choose an action:</p>
      <div className="modal-buttons flex space-x-6">
       
          <button
            className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
            onClick={onPermanentDelete} 
          >
            Permanent Delete
          </button>         

          <button
            className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
            onClick={onDisable} 
          >
            Disable
          </button> 

          <button
            className="flex items-center rounded-md p-1 text-white hover:from-cyan-500 hover:to-blue-500 bg-gradient-to-r from-pink-500 to-yellow-500"
            onClick={onCancel} 
          >
              Cancel
          </button>         
       
       

        
        
      </div>
    </div>
  );
};

export default DeleteConfirmationDialog;
