import React from 'react';
import { FaRegStar } from 'react-icons/fa';

const Email = ({ email, isSelected, onClick }) => {
  // Function to get initials from sender's name
  const getInitials = (name) => {
    const initials = name.split(' ')
      .map(word => word.charAt(0))
      .join('')
      .toUpperCase();
    return initials;
  };

  // Function to limit content to 100 words
  const limitContent = (content) => {
    const words = content.split(' ');
    if (words.length > 4) {
      return words.slice(0, 4).join(' ') + ' . . .';
    }
    return content;
  };

  function EmailContent({ content }) {
    //const cleanContent = DOMPurify.sanitize(content); // Sanitize content
    return <span classNamedangerouslySetInnerHTML={{ __html: content }} />;
  }

  return (
    <div
      className={`flex items-center p-1 cursor-pointer hover:bg-gray-100 rounded-lg ${isSelected ? 'bg-gray-25' : '' }`}
      onClick={onClick}
    >
      <div className="flex-shrink-0 w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center text-xl">
          {getInitials(email.surname)}
      </div>
      <div className="ml-4 flex-grow">
        <div className="flex gap-2 items-center">
        <div className={`text-sm text-black font-semibold w-[150px] truncate ${email.read ? 'font-normal' : 'font-bold'}`}>{email.surname}</div>
        <div className={`flex-1 truncate text-black tex-sm ${email.read ? 'font-normal' : 'font-bold'}`}>{limitContent(email.subject)} - {limitContent(email.content)}</div> 
     
        <div className="text-sm text-black ml-auto text-right">{email.create_date}</div>
      </div>

    </div>
      <div className="ml-4">
        <FaRegStar className={`text-yellow-500 ${email.starred ? 'opacity-100' : 'opacity-0'}`} />
      </div>
    </div>
  );
};

export default Email;
